/**
 * @packageDocumentation
 * @module Rtc
 * @preferred
 */

import Peer, { PeerCredential, SfuRoom, MeshRoom } from "skyway-js";

import * as CONFIG from "./WebRtcConfig";
import { MultiCastDelegate, StringToHexEncoderUtility, RtcMediaStreamWrapper } from "ui-gallery";
import Vue from "vue";
import { v4 } from "uuid";
import { IVideoChatConnection } from "./IVideoChatConnection";
import { Subject } from "rxjs";

export class PeerStream extends MediaStream {
    peerId = "";
}

(window as any).AudioContext = (window as any).AudioContext || (window as any).webkitAudioContext;

/**
 * @summary ビデオチャットの接続を提供します.
 */
export class VideoChatConnection implements IVideoChatConnection {
    // #region private field
    // メンバー数
    private _memberCount = 0;
    // Peer Id
    private _peerId = "";
    // apiKey
    private _apiKey = "";
    // Skyway Peer
    private _skywayPeer?: Peer = undefined;
    // Skyway Room
    private _skywayRoom?: SfuRoom = undefined;
    // バッファストリーム
    private _bufferStream?: RtcMediaStreamWrapper = undefined;
    // 表示用のストリームリスト
    private _remoteStreams: PeerStream[] = Vue.observable([]);
    // 画面共有を送信中かどうか
    private _isConnecting = false;

    // 誰かが入室した際の音
    private enterRoomSound: HTMLAudioElement;
    // 誰かが退室した際の音
    private leaveRoomSound: HTMLAudioElement;
    // ルームのキー
    private _roomKey = "";
    // #endregion

    // #region getters
    readonly remoteStreamsChangedHandler = new Subject<PeerStream[]>();
    readonly peerEnterHandler = new Subject<string>();
    readonly peerLeftHandler = new Subject<string>();

    /**
     * @summary APIキー
     */
    public get apiKey(): string {
        return this._apiKey;
    }

    /**
     * @summary Peer Id
     */
    public get peerId(): string {
        return this._peerId;
    }

    /**
     * @summary 表示用ストリームのリスト
     */
    public get remoteStreams(): PeerStream[] {
        return this._remoteStreams;
    }

    /**
     * @summary 現在参加しているメンバー数
     */
    public get memberCount(): number {
        return this._memberCount;
    }

    /**
     * @summary 接続中かどうか
     */
    public get isConnecting(): boolean {
        return this._isConnecting;
    }

    /**
     * @summary streamが存在するかどうか
     */
    public get hasStream(): boolean {
        if (this._bufferStream) {
            return true;
        }
        return false;
    }

    /**
     * @summary Stream
     */
    public get streamWrapper(): RtcMediaStreamWrapper | undefined {
        return this._bufferStream;
    }

    /**
     * @summary ルームのキー
     */
    public get roomKey(): string {
        return this._roomKey;
    }
    // #endregion

    // #region public methods
    /**
     * @summary コンストラクタ
     * @param apiKey APIキー
     * @param key ルームに接続するための一意のキー
     */
    public constructor(apiKey: string, key: string) {
        this._apiKey = apiKey;
        this._roomKey = key;
        this.enterRoomSound = document.getElementById("enterRoomSound") as HTMLAudioElement;
        this.leaveRoomSound = document.getElementById("leaveRoomSound") as HTMLAudioElement;
    }

    /**
     * @summary ルームへ接続
     * @param stream 送信するストリーム
     */
    public connect(stream: RtcMediaStreamWrapper | undefined): Promise<void> {
        if (this._isConnecting) {
            throw Error("既に接続中です。");
        }
        this._isConnecting = true;

        return new Promise((resolve, reject) => {
            this._bufferStream = stream;

            this._skywayPeer = new Peer({
                key: this.apiKey,
                debug: 0
            });

            // Peerへ接続したとき
            this._skywayPeer.once("open", () => {
                // ルームへ入室
                this._peerId = this._skywayPeer!.id;
                this._skywayRoom = this.joinRoom();
                resolve();
            });

            // Peerへの接続失敗時
            this._skywayPeer.on("error", err => {
                logger.error(["Skyway connection error.", err]);
                reject(new Error("接続に失敗しました"));
            });
        });
    }

    /**
     * @summary ルームのストリームを置換します.
     * @param stream 置換するストリーム
     */
    public setPeerStream(streamWrapper: RtcMediaStreamWrapper): void {
        this._bufferStream = streamWrapper;
        if (this._skywayRoom) {
            this._skywayRoom.replaceStream(streamWrapper.mediaStream);
        }
    }

    /**
     * @summary リロードします. ニックネームを変更する際は指定してください.
     */
    public async reload(): Promise<void> {
        if (this._isConnecting) {
            logger.log("disconnect");
            await this.disconnect();
            this.connect(this._bufferStream);
        }
    }

    /**
     *  @summary 通信を終了します.
     */
    public disconnect(): Promise<void> {
        return new Promise(resolve => {
            console.log("disconnect ----------------------------------------------------------------------------");
            const room = this._skywayRoom;
            if (room !== undefined) {
                room.close();
                this._skywayRoom = undefined;
            }
            this._isConnecting = false;
            for (const item of this._remoteStreams) {
                item.getAudioTracks().forEach(x => x.stop());
                item.getVideoTracks().forEach(x => x.stop());
            }
            this._remoteStreams = Vue.observable([]);
            this.remoteStreamsChangedHandler.next(this.remoteStreams);

            const peer = this._skywayPeer;
            if (peer !== undefined) {
                peer.destroy();
                this._skywayPeer = undefined;
                resolve();
            }
        });
    };
    // #endregion

    // #region private methods
    /**
     * @summary SkyWayルームへ入室します.
     * @returns {SFURoom|MeshRoom}
     */
    private joinRoom(): SfuRoom | undefined {
        if (this._skywayPeer === undefined) {
            return undefined;
        }
        logger.log(`WebRTC通信モード：${CONFIG.VIDEO_CHAT_PEER_MODE}`);

        const room = this._skywayPeer.joinRoom(
            "room_" + this._roomKey,
            {
                mode: CONFIG.VIDEO_CHAT_PEER_MODE,
                stream: this._bufferStream ? this._bufferStream.mediaStream : undefined,
            }
        );

        let isEnterPlaying = false;
        this.enterRoomSound.onended = () => (isEnterPlaying = false);
        // 新たにストリームを受け取ったときのイベント
        room.on("stream", (stream: PeerStream) => {
            if (!stream || !stream.active) {
                return;
            }
            this._remoteStreams.push(stream);
            this.remoteStreamsChangedHandler.next(this.remoteStreams);
            this.peerEnterHandler.next(stream.peerId);
            this._memberCount++;
            if (!isEnterPlaying) {
                this.enterRoomSound.play();
            }
        });
        room.on("error", e => {
            console.error(e);
        });

        let isLeavePlaying = false;
        this.leaveRoomSound.onended = () => (isLeavePlaying = false);
        // 相手の接続終了時の後処理
        room.on("peerLeave", peerId => {
            this._memberCount--;
            this.peerLeftHandler.next(peerId);
            this.removeFromremoteStreams(peerId);
            if (!isLeavePlaying) {
                this.leaveRoomSound.play();
            }
        });

        return room as SfuRoom;
    }

    /**
     * @summary リモートストリームから離脱したPeerを削除
     * @param peerId 削除するPeerID
     */
    private removeFromremoteStreams(peerId: string): void {
        const streams = this._remoteStreams;
        for (const key in streams) {
            /** 離脱したPeerIdのストリームを削除 */
            if (streams[key].peerId === peerId) {
                streams.splice(Number(key), 1);
            }
        }
        this.remoteStreamsChangedHandler.next(this.remoteStreams);
    }
    // #endregion
}
