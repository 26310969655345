

































































import { Vue, Component, Inject, Prop, Watch } from "vue-property-decorator";
import { StringToHexEncoderUtility, RtcMediaStreamWrapper, VolumeIndicator } from "ui-gallery";
import { WebRtcService } from "@/models/webRtc/WebRtcService";
import { PeerStream } from "@/models/webRtc/VideoChatRoom";
import { RoomInfoService } from "@/models/room/RoomInfoService";
import { container } from "tsyringe";
import { config } from "@/config";

@Component({ components: { VolumeIndicator } })
export default class VideoChat extends Vue {
    private itemHeight = "100%";
    private itemWidth = "100%";
    private stringToHexEncoderUtility = StringToHexEncoderUtility;
    private streamsCount = 0;
    private maxConnectionCount = config.maxConnectionCount;

    @Prop({ default: false }) small!: boolean;

    // #region private getters
    /**
     * ルーム情報に関するサービス
     */
    private readonly roomInfoService: RoomInfoService = container.resolve(RoomInfoService);

    /**
     * WebRTCに関するサービス
     */
    private readonly webRtcService: WebRtcService = container.resolve(WebRtcService);
    // #endregion

    /**
     * メッセージを通知します．
     */
    @Inject()
    private notify!: (message: string, timeout?: number, color?: string) => Promise<void>;


    // #region private methods
    private created() {
        window.addEventListener("resize", () => {
            if (this.webRtcService.videoChatConnection) {
                this.onDisplayStreamChanged(this.webRtcService.videoChatConnection.remoteStreams);
            }
        });
    }

    mounted() {
        if (this.webRtcService.videoChatConnection) {
            this.onDisplayStreamChanged(this.webRtcService.videoChatConnection.remoteStreams);
        }
        this.webRtcService.streamsChanged.add(this.onStreamsChanged);
    }

    beforeDestroy() {
        this.webRtcService.streamsChanged.remove(this.onStreamsChanged);
    }

    private onStreamsChanged(_: any) {
        this.$forceUpdate();
    }

    @Watch("webRtcService.videoChatConnection.remoteStreams")
    private onDisplayStreamChanged(streams: PeerStream[]) {
        if (this.webRtcService.videoChatConnection) {
            this.streamsCount = this.webRtcService.videoChatConnection.remoteStreams.length;
        }

        if (streams.length === 1) {
            this.itemWidth = "100%";
            this.itemHeight = "100%";
        }
        else if (streams.length === 2) {
            if (window.innerWidth < window.innerHeight) {
                this.itemWidth = "100%";
                this.itemHeight = "50%";
            }
            else {
                this.itemWidth = "50%";
                this.itemHeight = "100%";
            }
        }
        else if (streams.length === 3 || streams.length === 4) {
            this.itemWidth = "50%";
            this.itemHeight = "50%";
        }
        else if (streams.length === 5 || streams.length === 6) {
            if (window.innerWidth < window.innerHeight) {
                this.itemWidth = "50%";
                this.itemHeight = "calc(100% / 3)";
            }
            else {
                this.itemWidth = "calc(100% / 3)";
                this.itemHeight = "50%";
            }
        }
        else if (streams.length === 7 || streams.length === 8 || streams.length === 9) {
            this.itemWidth = "calc(100% / 3)";
            this.itemHeight = "calc(100% / 3)";
        }
        else {
            this.itemWidth = "25%";
            this.itemHeight = "25%";
        }
    }
    // #endregion
}
