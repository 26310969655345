import { EventEmitter } from 'events';
import { JanusPluginHandle } from './janus-plugin-handler';
import { JanusSession } from './janus-session';

const RoomMessageEventValues = [
    'offerRequest',
    'stream',
    'answer',
    'leave',
    'open',
    'close',
    'peerJoin',
    'peerLeave',
] as const;
type RoomMessageEventTuple = typeof RoomMessageEventValues;
type RoomMessageEvents = RoomMessageEventTuple[number];

export interface RoomOptions {
    pcConfig?: RTCConfiguration;
    mode?: 'sfu';
    stream?: MediaStream;
    iceServers?: RTCIceServer[];
}

interface PeerInfo {
    handle: JanusPluginHandle;
    connection: RTCPeerConnection;
}

export class Room extends EventEmitter {
    private publisher?: PeerInfo;
    private subscribers: { [key: string]: PeerInfo } = {};

    public constructor(
        private readonly name: string,
        private readonly peerId: string,
        private readonly options?: RoomOptions
    ) {
        super();
    }

    public async attachPublisher(
        session: JanusSession
    ): Promise<{ handle: JanusPluginHandle; connection: RTCPeerConnection }> {
        const connection = new RTCPeerConnection({ iceServers: this.options?.iceServers });
        const handle = new JanusPluginHandle(session);

        this.associate(connection, handle);

        console.log("1");

        handle.on('event', ev => {
            console.info(ev);
            const data = ev.plugindata.data;
            if (data.event === 'join' && data.room_id === this.name) {
                this.addMember(session, data.member_id);
            } else if (data.event === 'leave' && data.room_id === this.name) {
                this.removeMember(session, data.member_id);
            } else if (data.event === 'data') {
                console.log(data);
            }
        });

        await handle.attach('scenelive.plugin.groupcall');

        const stream = this.options?.stream;
        if (stream != null) {
            stream.getTracks().forEach(t => connection.addTrack(t, stream));
        }
        console.log("2");
        await this.waitForEvent('webrtcup', handle);

        console.log("3");
        const reply = await handle.sendMessage({
            kind: 'join',
            room_id: this.name, // eslint-disable-line camelcase
            member_id: this.peerId, // eslint-disable-line camelcase
            subscribe: { notification: true, data: true },
        });

        console.log("4");
        const occupants: string[] = reply.plugindata.data.response.members[this.name] ?? [];
        await Promise.all(occupants.map(memberId => this.addMember(session, memberId)));

        console.log("5");
        this.publisher = { handle, connection };

        return { handle, connection };
    }

    private async attachSubscriber(session: JanusSession, memberId: string): Promise<PeerInfo> {
        console.info(`attaching subscriber to ${memberId} for session: ${session}`);
        const connection = new RTCPeerConnection({ iceServers: this.options?.iceServers });
        const handle = new JanusPluginHandle(session);
        this.associate(connection, handle);

        connection.addEventListener('track', ev => {
            console.info(`attaching ${ev.track.kind} track from ${memberId} for session: ${session}`);
            this.emit('stream', ev.streams[0], memberId);
        });

        await handle.attach('scenelive.plugin.groupcall');
        await handle.sendMessage({
            kind: 'join',
            room_id: this.name, // eslint-disable-line camelcase
            member_id: this.peerId, // eslint-disable-line camelcase
            subscribe: { media: memberId },
        });
        await this.waitForEvent('webrtcup', handle);

        this.subscribers[memberId] = { handle, connection };

        return { handle, connection };
    }

    public async addMember(session: JanusSession, otherId: string): Promise<void> {
        console.info(`adding member: ${otherId}`);

        try {
            await this.attachSubscriber(session, otherId);
        } catch (e) {
            console.error(`error attaching subscriber: ${e}`);
        }
    }

    public removeMember(_session: JanusSession, memberId: string): void {
        console.info(`removing member ${memberId}`);
        const { [memberId]: subscriber, ...restSubscribers } = this.subscribers;
        if (subscriber != null) {
            subscriber.handle.detach();
            subscriber.connection.close();

            this.subscribers = restSubscribers;

            this.emit('leave', memberId);
        }
    }

    public close(): void {
        if (this.publisher != null) {
            this.publisher.handle.detach();
            this.publisher.connection.close();
        }

        for (const s of Object.keys(this.subscribers)) {
            this.subscribers[s].handle.detach();
            this.subscribers[s].connection.close();
        }
    }

    private associate(conn: RTCPeerConnection, handle: JanusPluginHandle): void {
        conn.addEventListener('icecandidate', ev => {
            try {
                handle.sendTrickle(ev.candidate);
            } catch (e) {
                console.error(`error trickling ice: ${e}`);
            }
        });
        conn.addEventListener('negotiationneeded', _ => {
            console.info(`sending new offer for handle: ${handle}`);
            const offer = conn.createOffer();
            const local = offer.then(o => conn.setLocalDescription(o));
            const remote = offer.then(j => handle.sendJsep(j)).then(r => conn.setRemoteDescription(r.jsep));

            try {
                Promise.all([local, remote]);
            } catch (e) {
                console.error(`error negotiating offer: ${e}`);
            }
        });

        handle.on('event', ev => {
            if (ev.jsep?.type !== 'offer') {
                return;
            }

            console.info(`accepting new offer for handle: ${handle}`);
            const answer = conn.setRemoteDescription(ev.jsep).then(_ => conn.createAnswer());
            const local = answer.then(a => conn.setLocalDescription(a));
            const remote = answer.then(j => handle.sendJsep(j));

            try {
                Promise.all([local, remote]);
            } catch (e) {
                console.error(`error negotiating answer: ${e}`);
            }
        });
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public waitForEvent(name: string, handle: JanusPluginHandle): Promise<any> {
        return new Promise((resolve, _reject) => handle.on(name, resolve));
    }

    public emit(event: 'stream', stream: MediaStream, peerId: string): boolean;
    public emit(event: 'offerRequest', data: { roomName: string }): boolean;
    public emit(event: 'answer', data: { roomName: string; answer: RTCSessionDescriptionInit }): boolean;
    public emit(event: 'leave', peerId: string): boolean;
    public emit(event: 'open'): boolean;
    public emit(event: 'close'): boolean;
    public emit(event: 'peerJoin', peerId: string): boolean;
    public emit(event: 'peerLeave', peerId: string): boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public emit(event: RoomMessageEvents, ...args: any[]): boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public emit(event: string, ...args: any[]): boolean {
        return super.emit(event, ...args);
    }
}
