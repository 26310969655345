





























import { Vue, Component, Prop } from "vue-property-decorator";
import { VolumeIndicator } from "ui-gallery";

@Component({ components: { VolumeIndicator } })
export default class DisplayVideoox extends Vue {
    @Prop({ default: 120 }) readonly height!: number | string;
    @Prop({ default: 200 }) readonly width!: number | string;
    @Prop({ default: true }) readonly isVideoEnabled!: boolean;
    @Prop({ default: false }) readonly isBackCamera!: boolean;
    @Prop({ default: () => new MediaStream() }) readonly stream!: MediaStream;
}
