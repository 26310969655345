var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-app-bar',{staticStyle:{"z-index":"0"},attrs:{"height":"48","color":"rgb(82, 82, 82)","fixed":"","clipped-left":"","app":"","elevation":1}},[_c('img',{staticStyle:{"filter":"brightness(180%)"},attrs:{"src":require("@/assets/title.png"),"width":"120"}}),_c('div',{staticClass:"white--text mt-1 ml-2 text-truncate"},[_vm._v(_vm._s(_vm.roomInfoService.roomInfo? _vm.roomInfoService.roomInfo.connectionNumber: ""))]),_c('v-spacer'),_c('div',{staticClass:"white--text mt-1 ml-2 mr-2"},[_vm._v(" "+_vm._s(_vm.roomInfoService.roomInfo ? _vm.roomInfoService.roomInfo.name : "")+" ")]),_c('v-btn',{attrs:{"text":"","color":"white","fab":"","small":""},on:{"click":_vm.reload}},[_c('v-icon',[_vm._v("mdi-refresh")])],1)],1),_c('v-main',[_c('div',{staticClass:"fixed",staticStyle:{"top":"48px","bottom":"0","right":"0","left":"0","z-index":"999999","transition":"all 0.4s"}},[_c('DragableBoxView',{ref:"dragableBox",attrs:{"height":_vm.videoHeight,"width":_vm.videoWidth,"left":"12px","top":"48px","disableFixed":true,"fixed":true,"hideHeader":true},on:{"click":_vm.switchLarge}},[(_vm.webRtcService.transmitStream)?[(_vm.screenMode === _vm.screenModeType.VideoChat)?_c('DisplayVideoBox',{attrs:{"isVideoEnabled":_vm.webRtcService.transmitStream.isVideoEnabled,"stream":_vm.webRtcService.transmitStream.mediaStream,"isBackCamera":_vm.webRtcService.transmitStream.isBackCamera,"height":_vm.videoHeight,"width":_vm.videoWidth}}):(_vm.screenMode)?_c('VideoChat',{attrs:{"small":true}}):_vm._e()]:_vm._e()],2),_c('div',{staticClass:"strech"},[(_vm.screenMode === _vm.screenModeType.ScreenShare)?_c('v-fade-transition',[_c('ScreenShare',{attrs:{"hideOperation":true,"disabled":true}})],1):(_vm.screenMode === _vm.screenModeType.VideoChat)?_c('v-fade-transition',[_c('VideoChat')],1):(
                            _vm.screenMode === _vm.screenModeType.DocumentShare
                        )?_c('v-fade-transition',[_c('DocumentShare')],1):_vm._e(),_c('div',{staticStyle:{"width":"48px","position":"absolute","right":"0","top":"0px"}},[_c('RightCommandBox',{staticStyle:{"z-index":"1"},on:{"showProfile":_vm.showProfile},model:{value:(_vm.screenMode),callback:function ($$v) {_vm.screenMode=$$v},expression:"screenMode"}}),_c('v-badge',{attrs:{"color":"primary","overlap":"","value":_vm.unreadChatMessageCount,"right":"","bottom":"","inline":""},scopedSlots:_vm._u([{key:"badge",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.unreadChatMessageCount))])]},proxy:true}])},[_c('v-btn',{staticClass:"mt-2 white--text",attrs:{"fab":"","color":"#21212187","small":""},on:{"click":function($event){_vm.isRightPanelOpend = !_vm.isRightPanelOpend;
                                    _vm.unreadChatMessageCount = 0;}}},[_c('v-icon',[_vm._v("mdi-chat-outline")])],1)],1)],1),_c('v-container',{staticClass:"pa-1",staticStyle:{"bottom":"0","right":"0","left":"0","z-index":"102","position":"absolute"}},[_c('v-row',{staticStyle:{"height":"64px"},attrs:{"justify":"center","align":"center"}},[_c('FooterCommandBox',{attrs:{"isAudioEnabled":_vm.webRtcService.transmitStream
                                        .isAudioEnabled,"isAudioButtonEnabled":_vm.webRtcService.audioDevices.length,"isVideoEnabled":_vm.webRtcService.transmitStream
                                        .isVideoEnabled,"isVideoButtonEnabled":_vm.webRtcService.videoDevices.length},on:{"audioMuteClicked":function($event){_vm.webRtcService.transmitStream.isAudioEnabled = !_vm.webRtcService
                                        .transmitStream.isAudioEnabled;
                                    _vm.rewriteQueryStr();},"videoMuteClicked":function($event){_vm.webRtcService.transmitStream.isVideoEnabled = !_vm.webRtcService
                                        .transmitStream.isVideoEnabled;
                                    _vm.rewriteQueryStr();},"settingOpenClicked":_vm.openSettingDialog,"fullScreenClicked":_vm.switchFullScreenClicked,"hungupClicked":_vm.hungupClicked}})],1)],1)],1)],1)]),(_vm.webRtcService.videoChatConnection)?_c('div',_vm._l((_vm.webRtcService.videoChatConnection
                    .remoteStreams),function(video,key){return _c('audio',{key:key,ref:"audioElement",refInFor:true,attrs:{"autoplay":""},domProps:{"srcObject":video},on:{"loadeddata":_vm.onSelectedOutputDeviceIdChanged}})}),0):_vm._e(),_c('div',{staticStyle:{"z-index":"9999999"}},[(_vm.isLoading)?_c('div',{staticClass:"fixed-strech",staticStyle:{"background":"#000000c4","z-index":"10000"}},[_c('v-progress-circular',{staticClass:"absolute-center",attrs:{"size":200,"color":"secondary","indeterminate":""}})],1):_vm._e(),_c('div',{staticStyle:{"z-index":"9999999"}},[_c('v-dialog',{attrs:{"fullscreen":""},model:{value:(_vm.isRightPanelOpend),callback:function ($$v) {_vm.isRightPanelOpend=$$v},expression:"isRightPanelOpend"}},[_c('v-card',{staticClass:"strech"},[_c('v-btn',{staticClass:"fixed",staticStyle:{"right":"0","top":"0","z-index":"99999"},attrs:{"text":"","fab":"","color":"primary"},on:{"click":function($event){_vm.isRightPanelOpend=false}}},[_c('v-icon',{attrs:{"size":"32"}},[_vm._v("mdi-close")])],1),_c('div',{staticClass:"deep-purple--text headline pt-2 ml-2"},[_c('v-icon',{staticClass:"mr-2",attrs:{"size":"32","color":"deep-purple"}},[_vm._v("mdi-chat-outline")]),_vm._v("チャット ")],1),_c('ChatView',{staticStyle:{"height":"calc(100% - 44px)"},on:{"recieved":_vm.onChatRecieved}})],1)],1),_c('ConfirmLeaveRoomDialog',{ref:"confirmLeaveRoomDialog",attrs:{"isExtern":true}}),_c('ConnectionSettingDialog',{ref:"connectionSettingDialog"}),_c('ProfileDisplayDialog',{ref:"profileDisplayDialog",on:{"send":_vm.onSend}}),_c('ProfileSelectDialog',{ref:"profileSelectDialog",attrs:{"isShowMyProfile":false},on:{"preview":function($event){return _vm.onPreviewProfile($event[0], $event[1])},"profileSelected":_vm.onSendProfileToRoom}}),_c('v-snackbar',{attrs:{"top":"","color":_vm.notifyColor,"timeout":_vm.notifyTimeout},scopedSlots:_vm._u([{key:"action",fn:function(ref){
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"dark":"","text":"","fab":""},on:{"click":function($event){_vm.isShowNotify = false}}},'v-btn',attrs,false),[_c('v-icon',[_vm._v("mdi-close")])],1)]}}]),model:{value:(_vm.isShowNotify),callback:function ($$v) {_vm.isShowNotify=$$v},expression:"isShowNotify"}},[_vm._v(" "+_vm._s(_vm.notifyMessage)+" ")]),_c('ConfirmDialog',{ref:"confirmDialog"})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }