export interface JanusSessionOption {
    verbose: boolean;
    timeoutMs: number;
    keepaliveMs: number;
}

interface TransactionType {
    resolve: Function; // eslint-disable-line @typescript-eslint/ban-types
    reject: Function; // eslint-disable-line @typescript-eslint/ban-types
    timeout?: number;
    type: string;
}

type OutputType = (data: string | ArrayBuffer | SharedArrayBuffer | Blob | ArrayBufferView) => void;

export class JanusSession {
    private id: string | undefined = undefined;
    private nextTxId = 0;
    private transactions: { [key: string]: TransactionType } = {};
    // eslint-disable-next-line @typescript-eslint/ban-types
    private eventHandler: { [key: string]: Function[] } = {};
    private keepaliveTimeout: number | undefined = undefined;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public isError: (_: any) => boolean = signal => signal.janus === 'error';

    public constructor(private readonly output: OutputType, private readonly options?: Partial<JanusSessionOption>) {
        this.options = { verbose: false, timeoutMs: 10000, keepaliveMs: 30000, ...options };
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public async create(): Promise<any> {
        const resp = await this.send('create');
        this.id = resp.data.id;

        return resp;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public async destroy(): Promise<any> {
        const resp = await this.send('destroy');
        this.dispose();

        return resp;
    }

    public dispose(): void {
        this.killKeepalive();
        this.eventHandler = {};

        for (const txId of Object.keys(this.transactions)) {
            if (this.transactions[txId] != null) {
                const transaction = this.transactions[txId];
                clearTimeout(transaction.timeout);
                transaction.reject(new Error('session was disposed'));
                delete this.transactions[txId];
            }
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public on(event: string, callback: (_: any) => void): void {
        let handlers = this.eventHandler[event];
        if (handlers == null) {
            handlers = this.eventHandler[event] = [];
        }

        handlers.push(callback);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
    public receive(signal: any): void {
        if (this.options?.verbose) {
            this.logIncoming(signal);
        }

        if (signal.session_id !== this.id) {
            console.warn(`incorrect session ID: ${signal.session_id}, expected: ${this.id}`);
        }

        const responseType = signal.janus;
        const handlers = this.eventHandler[responseType];
        if (handlers != null) {
            for (const h of handlers) {
                h(signal);
            }
        }

        if (signal.transaction == null) {
            return;
        }

        const transaction = this.transactions[signal.transaction];
        if (transaction == null) {
            return;
        }

        if (responseType === 'ack' && transaction.type === 'message') {
            return;
        }

        clearTimeout(transaction.timeout);

        delete this.transactions[signal.transaction];
        (this.isError(signal) ? transaction.reject : transaction.resolve)(signal);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
    public send(type: string, signal?: any): Promise<any> {
        const s = { ...signal, transaction: `${this.nextTxId++}` };
        return new Promise((resolve, reject) => {
            let timeout: number | undefined = undefined;
            if (this.options?.timeoutMs) {
                timeout = setTimeout(() => {
                    delete this.transactions[s.transaction];
                    reject(new Error(`timed out ${s.transaction}`));
                }, this.options.timeoutMs) as any;
            }

            this.transactions[s.transaction] = { resolve, reject, timeout, type };
            this.transmit(type, s);
        });
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private transmit(type: string, signal?: any): void {
        let s = { ...signal, janus: type };

        if (this.id != null) {
            s = { ...s, session_id: this.id }; // eslint-disable-line camelcase
        }

        if (this.options?.verbose) {
            this.logOutgoing(s);
        }

        this.output(JSON.stringify(s));
        this.resetKeepalive();
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private logOutgoing(_signal: any): void {
        // let kind = signal.janus;
        // if (kind === 'message' && signal.jsep) {
        //     kind = signal.jsep.type;
        // }
        // const message = `> Outgoing janus ${kind || 'signal'} (#${signal.transaction}): `;
        // logger.log(`%c${message}`, 'color: #040', signal);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private logIncoming(_signal: any): void {
        // const kind = signal.janus;
        // const message = signal.transaction
        //     ? `< Incoming janus ${kind || 'signal'} (#${signal.transaction}): `
        //     : `< Incoming janus ${kind || 'signal'}: `;
        // logger.log(`%c${message}`, 'color: #040', signal);
    }

    private sendKeepalive(): Promise<void> {
        return this.send('keepalive');
    }

    private killKeepalive(): void {
        clearTimeout(this.keepaliveTimeout);
    }

    private resetKeepalive(): void {
        this.killKeepalive();
        if (this.options?.keepaliveMs) {
            this.keepaliveTimeout = setTimeout(() => {
                this.sendKeepalive().catch(e => console.error(`keep alive error: ${e}`));
            }, this.options.keepaliveMs) as any;
        }
    }
}
