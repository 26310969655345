






















































































































































































import { Component, Vue, Prop, Inject, Ref } from "vue-property-decorator";
import { ColorPalette, SplitView, BrowserUtility, BrowserType } from "ui-gallery";
import { DrawingModeType } from "@/models/drawing/DrawingModeType";
import DrawingCanvas from "@/components/Connections/organisms/DrawingCanvas.vue";
import DocumentSelectDialog from "@/components/Connections/templates/DocumentSelectDialog.vue";
import { Document } from "@/models/documents/Document";
import { DocumentPage } from "@/models/documents/DocumentPage";
import { animate } from "@/models/utilities/animate";
import { container } from "tsyringe";
import { DocumentManagementsService } from "@/models/documents/DocumentManagementsService";
import { DocumentShareService } from "@/models/documents/DocumentShareService";
import { WebRtcService } from "@/models/webRtc/WebRtcService";
import { AuthService } from "@/models/auth/AuthService";
import { RoomInfoService } from "@/models/room/RoomInfoService";
import { DrawingService } from "@/models/drawing/DrawingService";
import { IRoomInfo } from "../../../models/room/IRoomInfo";
import { config } from "@/config";

@Component({ components: { ColorPalette, DocumentSelectDialog, DrawingCanvas, SplitView } })
export default class DocumentShare extends Vue {
    private isVertical = false;
    private drawingModeTypes = DrawingModeType;

    private readonly documentShareService = container.resolve(DocumentShareService);
    private readonly roomInfoService = container.resolve(RoomInfoService);
    private readonly drawingService = container.resolve(DrawingService);
    private readonly webRtcService = container.resolve(WebRtcService);
    private readonly authService = container.resolve(AuthService);

    config = config;

    @Prop({ default: false }) readonly hideOperation!: boolean;

    private get token() {
        return this.authService.token;
    }

    /**
     * 現在選択中のページ番号
     */
    private get currentPageNumber(): string {
        return this.documentShareService.selected.toString();
    }
    private set currentPageNumber(i: string) {
        if (!i) return;
        const _i = Number(i);
        if (_i) {
            this.setSelectedWithAnimation(Math.max(1, Math.min(_i, this.documentShareService.document ? this.documentShareService.document.pages.length : 0)));
        }
    }

    private get isMobile(): boolean {
        return BrowserUtility.hasFlag(BrowserType.Mobile);
    }

    @Inject()
    private message!: (title: string, timeout?: number, color?: string) => Promise<void>;

    @Inject()
    private confirm!: (title: string) => Promise<void>;

    @Inject()
    private notify!: (message: string, timeout?: number, color?: string) => void;

    @Ref() drawingCanvas!: DrawingCanvas;
    private resetZoom() {
        this.drawingCanvas.resetZoom();
    }

    private minus() {
        this.drawingCanvas.minus();
    }

    private plus() {
        this.drawingCanvas.plus();
    }

    /**
     * コンポーネントが作成されたきに実行されます．
     */
    private async created(): Promise<void> {
        window.addEventListener("resize", this.onResize);
        this.documentShareService.documentRecieved.add(this.onDocumentRecieved);
        this.documentShareService.seekRecieved.add(this.onSeekRecieved);
        this.documentShareService.videoPlayRecieved.add(this.onPlayRecieved);
        this.drawingService.drawingModeType = DrawingModeType.Move;
    }

    private beforeDestroy() {
        window.removeEventListener("resize", this.onResize);
        this.documentShareService.documentRecieved.remove(this.onDocumentRecieved);
        this.documentShareService.seekRecieved.remove(this.onSeekRecieved);
        this.documentShareService.videoPlayRecieved.remove(this.onPlayRecieved);
    }

    private mounted() {
        this.onResize();
    }

    private onDocumentRecieved() {
        this.notify("資料を受信しました");
    }

    private onPlayClicked() {
        if (this.hideOperation) return;
        this.documentShareService.videoPlay();
    }

    private onSeeked() {
        if (this.hideOperation) return;
        const video = this.$refs.video as HTMLVideoElement | undefined;
        if (video) {
            this.documentShareService.videoSeek(video.currentTime);
        }
    }

    /**
     * プレイリクエストを受信したとき
     */
    private onPlayRecieved() {
        const video = this.$refs.video as HTMLVideoElement | undefined;
        if (video) {
            video.play();
        }
    }

    private onSeekRecieved(position: number) {
        const video = this.$refs.video as HTMLVideoElement | undefined;
        if (video) {
            video.pause();
            video.currentTime = position;
            video.play();
        }
    }

    /**
     * グリッドの高さをレスポンシブします.
     */
    private onResize() {
        if (window.innerWidth < 800) {
            this.isVertical = true;
        }
        else {
            this.isVertical = false;
        }
    }

    /**
     * クリアがクリックされたとき
     */
    private removeCurrentShape() {
        const canvas = this.$refs.drawingCanvas as DrawingCanvas | undefined;
        if (!canvas) return;
        canvas.removeCurrentShape();
    }

    /**
     * すべてクリアがクリックされたとき
     */
    private removeAllShape() {
        const canvas = this.$refs.drawingCanvas as DrawingCanvas | undefined;
        if (!canvas) return;
        canvas.removeAllShape();
    }

    /**
     * 前のページへ移動します.
     */
    private previousPage() {
        this.setSelectedWithAnimation(Math.max(1, --this.documentShareService.selected));
    }

    /**
     * 次のページへ移動します.
     */
    private nextPage() {
        this.setSelectedWithAnimation(Math.min(++this.documentShareService.selected, this.documentShareService.document ? this.documentShareService.document.pages.length : 0));
    }

    private async setSelectedWithAnimation(index: number) {
        const el = this.$refs.drawingArea as HTMLElement | undefined;
        if (el) {
            const tmp = this.documentShareService.selected;
            await animate(el, "opacity", "0", 0);
            if (this.documentShareService.selected !== tmp) return;
            this.documentShareService.selected = index;
            await animate(el, "opacity", "1", 0);
        }
    }

    /**
     * ページが選択されたときに実行されます。
     * @param page ページ
     */
    private async onSelectDocument(index: number): Promise<void> {
        this.setSelectedWithAnimation(index + 1);
    }

    /**
     * 0.75 - 2 を50% - 300%に変換
     */
    private toPercent(value: number) {
        return Math.round(((value - 0.75) / 0.25 / 2 + 0.5) * 100);
    }
}
