import { EventEmitter } from 'events';
import { JanusSession } from './connections/janus-session';
import { Room } from './connections/room';

interface PeerOptions {
    key: string;
    memberId: string;
    host: string;
    debug?: 0 | 1 | 2 | 3;
    verbose?: boolean;
    port?: number;
    iceServers?: RTCIceServer[];
}

export class Peer extends EventEmitter {
    private ws?: WebSocket;
    private session?: JanusSession;

    public get id(): string {
        return this.options.memberId;
    }

    public constructor(private options: PeerOptions) {
        super();
        this.ws = new WebSocket(this.options.host, 'janus-protocol');
        this.session = new JanusSession(this.ws.send.bind(this.ws), { verbose: this.options.verbose ?? true });
        this.ws.addEventListener('message', ev => this.session?.receive(JSON.parse(ev.data)));
        this.ws.addEventListener('open', async _ => {
            await this.session?.create();
            this.emit('open');
        });
    }

    public joinRoom(roomName: string, { stream }: { stream?: MediaStream }): Room | null {
        return this.initSfuRoom(roomName, stream);
    }

    public destroy(): void {
        this.session?.destroy();
        this.ws?.close();
    }

    private initSfuRoom(roomName: string, stream?: MediaStream): Room | null {
        if (this.session == null) {
            return null;
        }

        const room = new Room(roomName, this.options.memberId, { stream, iceServers: this.options.iceServers });
        room.attachPublisher(this.session);

        return room;
    }

    public emit(event: 'message'): boolean;
    public emit(event: 'open'): boolean;
    public emit(event: 'stream', stream: MediaStream & { peerId: string }): boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public emit(event: string, ...args: any[]): boolean {
        return super.emit(event, args);
    }
}
