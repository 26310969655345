import { JanusSession } from './janus-session';

export class JanusPluginHandle {
    private id: string | undefined = undefined;
    public constructor(private readonly session: JanusSession) {}

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public async attach(plugin: string): Promise<any> {
        const payload = { plugin, 'force-bundle': true, 'force-rtcp-mux': true };
        const resp = await this.session.send('attach', payload);
        this.id = resp.data.id;

        return resp;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public detach(): Promise<any> {
        return this.send('detach');
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public on(event: string, callback: (_: any) => void): void {
        return this.session.on(event, signal => {
            if (signal.sender === this.id) {
                callback(signal);
            }
        });
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
    public send(type: string, signal?: any): Promise<any> {
        // eslint-disable-next-line camelcase
        return this.session.send(type, { ...signal, handle_id: this.id });
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
    public sendMessage(body: any): Promise<any> {
        return this.send('message', { body });
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
    public sendJsep(jsep: any): Promise<any> {
        return this.send('message', { body: {}, jsep });
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
    public sendTrickle(candidate: any): Promise<any> {
        return this.send('trickle', { candidate });
    }
}
