

































































import { Component, Vue, Prop } from "vue-property-decorator";
import { BrowserUtility, BrowserType } from "ui-gallery";
@Component
export default class FooterCommandBox extends Vue {
    @Prop({ default: false }) readonly isVideoEnabled!: boolean;
    @Prop({ default: false }) readonly isVideoButtonEnabled!: boolean;
    @Prop({ default: false }) readonly isAudioEnabled!: boolean;
    @Prop({ default: false }) readonly isAudioButtonEnabled!: boolean;
    isAppleIos = BrowserUtility.hasFlag(BrowserType.ios);
    fullscreen() {
        if (this.isAppleIos) return;
        this.$emit("fullScreenClicked");
    }
}
