import { Subject } from "rxjs";
import { DeviceMediaStreamWrapper, RtcMediaStreamWrapper } from "ui-gallery";
import { IVideoChatConnection, PeerStream } from "./IVideoChatConnection";
import { Peer, Room } from "janus-groupcall-client/src";
import Vue from "vue";
import { v4 } from "uuid";

/**
 * Janusのグループ通話クライアント
 */
export class JanusVideoChatConnection implements IVideoChatConnection {
    // 表示用のストリームリスト
    private _remoteStreams: PeerStream[] = Vue.observable([]);
    remoteStreamsChangedHandler = new Subject<PeerStream[]>();
    peerEnterHandler = new Subject<string>();
    peerLeftHandler = new Subject<string>();
    _isConnecting = false;
    _peer?: Peer;
    _memberCount = 0;
    // 誰かが入室した際の音
    private enterRoomSound: HTMLAudioElement;
    // 誰かが退室した際の音
    private leaveRoomSound: HTMLAudioElement;

    private _peerId = "";

    private _room?: Room;
    private _bufferStream?: RtcMediaStreamWrapper;

    get peerId() {
        return this._peerId;
    }

    get remoteStreams() {
        return this._remoteStreams;
    }

    get isConnecting() {
        return this._isConnecting;
    }

    get hasStream() {
        return !!this._bufferStream;
    }

    constructor(readonly roomName: string) {
        this._peerId = v4();
        this.enterRoomSound = document.getElementById("enterRoomSound") as HTMLAudioElement;
        this.leaveRoomSound = document.getElementById("leaveRoomSound") as HTMLAudioElement;
    }

    connect(stream: RtcMediaStreamWrapper | undefined): Promise<void> {
        if (this._isConnecting) {
            throw Error("既に接続中です。");
        }
        this._isConnecting = true;
        this._bufferStream = stream;
        return new Promise((resolve, reject) => {
            const peer = this._peer = new Peer({
                debug: 3,
                key: "hogehoge",
                host: "wss://sfu1.scene-live.com:8989",
                iceServers: [{
                    credential: "mytestpass-110315",
                    username: "sl-y.ichimaru",
                    urls: ["turn:sfu1.scene-live.com:3478"]
                }],
                memberId: this.peerId
            });
            this._peer.once("open", async () => {
                this._room = this.joinRoom();
            });
            resolve();
        });
    }

    /**
     * @summary ルームのストリームを置換します.
     * @param stream 置換するストリーム
     */
    public async setPeerStream(streamWrapper: RtcMediaStreamWrapper): Promise<void> {
        this._bufferStream = streamWrapper;
        if (this._room) {
            await this.disconnect();
            await this.connect(streamWrapper);
            // this._room.replaceStream(streamWrapper.mediaStream);
        }
    }

    /**
     * @summary リロードします. ニックネームを変更する際は指定してください.
     */
    public async reload(): Promise<void> {
        if (this._isConnecting) {
            logger.log("disconnect");
            await this.disconnect();
            this.connect(this._bufferStream);
        }
    }

    /**
     *  @summary 通信を終了します.
     */
    public disconnect(): Promise<void> {
        return new Promise(resolve => {
            console.log("disconnect ----------------------------------------------------------------------------");
            const room = this._room;
            if (room !== undefined) {
                room.close();
                this._room = undefined;
            }
            this._isConnecting = false;
            for (const item of this._remoteStreams) {
                item.getAudioTracks().forEach(x => x.stop());
                item.getVideoTracks().forEach(x => x.stop());
            }
            this._remoteStreams = Vue.observable([]);
            this.remoteStreamsChangedHandler.next(this.remoteStreams);

            const peer = this._peer;
            if (peer !== undefined) {
                peer.destroy();
                this._peer = undefined;
                resolve();
            }
        });
    };
    // #endregion

    // #region private methods
    /**
     * @summary ルームへ入室します.
     * @returns Room
     */
    private joinRoom(): Room | undefined {
        console.log(this._bufferStream?.mediaStream);
        const room = this._peer?.joinRoom(
            this.roomName,
            {
                stream: this._bufferStream?.mediaStream
            });

        if (!room) {
            throw new Error("Failed to enter room");
        }

        room.on("stream", (stream, memberId) => {
            console.log("stream received", stream, memberId);
            if (!stream.active) {
                console.warn("stream is not active");
                return;
            }
            (stream as any).peerId = memberId;

            if (!stream || !stream.active) {
                return;
            }
            this._remoteStreams = [...this.remoteStreams.filter(x => x.peerId !== memberId), stream];
            this.remoteStreamsChangedHandler.next(this.remoteStreams);
            this.peerEnterHandler.next(memberId);
            this._memberCount++;
            if (!isEnterPlaying) {
                this.enterRoomSound.play();
            }
        });

        room.on("leave", peerId => {
            console.log(`${peerId} has left`);
            this._memberCount--;
            this.peerLeftHandler.next(peerId);
            this._remoteStreams = this.remoteStreams.filter(x => x.peerId !== peerId);
            this.remoteStreamsChangedHandler.next(this.remoteStreams);
            if (!isLeavePlaying) {
                this.leaveRoomSound.play();
            }
        });

        room.on("error", e => {
            console.error(e);
        });

        let isEnterPlaying = false;
        this.enterRoomSound.onended = () => (isEnterPlaying = false);
        let isLeavePlaying = false;
        this.leaveRoomSound.onended = () => (isLeavePlaying = false);

        return room;
    }
}