import { RoomInfoService } from "@/models/room/RoomInfoService";
import { WebRtcService } from "@/models/webRtc/WebRtcService";
import {
    DeviceStreamManager,
    VirtualVideoStreamGenerator,
    RtcMediaStreamWrapper,
    DeviceMediaStreamWrapper,
    MultiCastDelegate,
    getUrlParameter
} from "ui-gallery";
import { VideoChatConnection } from "./VideoChatRoom";
import { ConnectionSetting, QualityType } from "../connection/ConnectionSetting";
import { injectable, inject, singleton } from "tsyringe";
import { WebSocketService } from "@/models/web-socket/WebSocketService";
import Vue from "vue";
import { StreamInfo } from "./StreamInfo";
import { WebSocketMessage } from "../web-socket/WebSocketMessage";
import { delay } from "@/models/utilities/timer";
import { SpeechToTextService } from "../speech-to-text/SpeechToTextService";
import { JanusVideoChatConnection } from "./JanusVideoChatConnection";

/**
 * ビデオチャットに関するユースケースを提供します.
 */
@singleton()
export class VideoChatUseCase {
    // #region public methods
    constructor(
        @inject(RoomInfoService) private readonly roomInfoService: RoomInfoService,
        @inject(WebRtcService) private readonly webRtcService: WebRtcService,
        @inject(SpeechToTextService) private readonly speechToTextService: SpeechToTextService,
        @inject(WebSocketService) private readonly webSocketService: WebSocketService<WebSocketMessage<StreamInfo | undefined>>
    ) {
    }

    private serve() {
        if (!this.roomInfoService.roomInfo) throw new Error("failed to connect websocket video");
        this.webSocketService.onRecieved.add(e => {
            if (e.type === "syncDevice") {
                if (e.data) {
                    this.webRtcService.streams[e.data.peerId] = e.data;
                    this.webRtcService.streamsChanged.invoke(this.webRtcService.streams);
                }
            }
            else if (e.type === "requestSyncDevice") {
                this.syncStream();
            }
        });
    }

    public syncStream() {
        if (!this.webRtcService.videoChatConnection) throw new Error("failed connection");
        this.webSocketService.send("broadcastToRoom", new WebSocketMessage("syncDevice", {
            isAudioEnabled: this.webRtcService.transmitStream.isAudioEnabled,
            isVideoEnabled: this.webRtcService.transmitStream.isVideoEnabled,
            peerId: this.webRtcService.videoChatConnection.peerId,
            nickName: this.webRtcService.connectionSetting.nickName
        }));
    }

    public requestSync() {
        this.webSocketService.send("broadcastToRoom", new WebSocketMessage("requestSyncDevice", undefined));
    }

    /**
     * ビデオチャットへ接続します.
     * @description デバイスの有効性は、GETパラメータにデバイスIDがセットされていれば有効、
     * されていなければ無効となります。
     */
    public async connect(apiKey?: string): Promise<boolean> {
        try {
            const audioDevice = this.webRtcService.connectionSetting.audioDeviceId;
            const videoDevice = this.webRtcService.connectionSetting.videoDeviceId;
            if (this.webRtcService.videoDevices.length === 0 &&
                this.webRtcService.audioDevices.length === 0) {
                this.webRtcService.setTransmitStream(new RtcMediaStreamWrapper(new MediaStream(), true));
                return true;
            }

            const { width, height } = (() => {
                if (this.webRtcService.connectionSetting.quality === QualityType.Low) {
                    return { width: 240, height: 160 };
                }
                else if (this.webRtcService.connectionSetting.quality === QualityType.Middle) {
                    return { width: 480, height: 360 };
                }
                return { width: 640, height: 480 };
            })();
            DeviceStreamManager.disposeAt(this.webRtcService.transmitStream as DeviceMediaStreamWrapper | null ?? new DeviceMediaStreamWrapper(new MediaStream()));
            const stream = await DeviceStreamManager.getDeviceStream(
                this.webRtcService.videoDevices.length !== 0,
                this.webRtcService.audioDevices.length !== 0,
                videoDevice,
                audioDevice,
                width,
                height
            );
            if (!stream) {
                this.webRtcService.setTransmitStream(new RtcMediaStreamWrapper(new MediaStream(), true));
                return false;
            }
            this.speechToTextService.switchDevice(audioDevice);
            const virtual = await this.apply(stream);

            // 初期のデバイスの有効性のセット
            virtual.isAudioEnabled = Boolean(this.webRtcService.connectionSetting.isAudioEnabled);
            virtual.isVideoEnabled = Boolean(this.webRtcService.connectionSetting.isVideoEnabled);

            // 選択中のデバイスIDをサービスへ保存
            this.webRtcService.connectionSetting.audioDeviceId = audioDevice;
            this.webRtcService.connectionSetting.videoDeviceId = videoDevice;
            // 送信用ストリームをサービスへ保存
            this.webRtcService.setTransmitStream(virtual);
            return true;
        }
        catch (ex) {
            logger.error(["ビデオチャットの接続に失敗しました。", ex]);
            console.error("ビデオチャットの接続に失敗しました。", ex);
            this.webRtcService.setTransmitStream(new RtcMediaStreamWrapper(new MediaStream(), true));
        }
        finally {
            // ビデオチャットへ接続
            const connection = (() => {
                if (getUrlParameter("provider") === "skyway") {
                    return new VideoChatConnection(apiKey || "792359d7-d78b-4ab1-9c7d-0dae465e09df", this.webRtcService.connectionSetting.roomId.toString());
                }
                return new JanusVideoChatConnection(this.webRtcService.connectionSetting.roomId.toString());
            })();
            this.webRtcService.setVideoChatConnection(connection);

            // 入退室イベント
            connection.peerLeftHandler.subscribe(peerId => {
                this.webRtcService.left.invoke(peerId);
            });
            connection.peerEnterHandler.subscribe(peerId => {
                this.webRtcService.enter.invoke(peerId);
            });
            await connection.connect(this.webRtcService.transmitStream);

            this.serve();
            this.requestSync();
        }
        return false;
    }

    /**
     * 接続情報を更新します.
     * @param connectionSetting 接続設定
     */
    public async update(connectionSetting: ConnectionSetting): Promise<boolean> {
        try {
            if (!this.webRtcService.videoChatConnection || !this.webRtcService.transmitStream) return false;
            const lastIsVideoEnabled = this.webRtcService.transmitStream.isVideoEnabled;
            const lastIsAudioEnabled = this.webRtcService.transmitStream.isAudioEnabled;

            const audioDevice = connectionSetting.audioDeviceId;
            const videoDevice = connectionSetting.videoDeviceId;

            const { width, height } = (() => {
                if (this.webRtcService.connectionSetting.quality === QualityType.Low) {
                    return { width: 180, height: 120 };
                }
                else if (this.webRtcService.connectionSetting.quality === QualityType.Middle) {
                    return { width: 480, height: 360 };
                }
                return { width: 640, height: 480 };
            })();
            DeviceStreamManager.disposeAt(this.webRtcService.transmitStream as DeviceMediaStreamWrapper | null ?? new DeviceMediaStreamWrapper(new MediaStream()));
            const stream = await DeviceStreamManager.getDeviceStream(
                this.webRtcService.videoDevices.length !== 0,
                this.webRtcService.audioDevices.length !== 0,
                videoDevice,
                audioDevice,
                width,
                height
            );
            if (!stream) return false;

            // 初期のデバイスの有効性のセット
            stream.isAudioEnabled = lastIsAudioEnabled && audioDevice !== "";
            stream.isVideoEnabled = lastIsVideoEnabled && videoDevice !== "";

            this.speechToTextService.switchDevice(audioDevice);

            // 選択中のデバイスIDをサービスへ保存
            this.webRtcService.connectionSetting.audioDeviceId = audioDevice;
            this.webRtcService.connectionSetting.videoDeviceId = videoDevice;
            // 送信用ストリームをサービスへ保存
            this.webRtcService.setTransmitStream(stream);

            this.webRtcService.videoChatConnection.setPeerStream(stream);
            // this.webRtcService.videoChatConnection.reload(connectionSetting.nickName);

            Object.assign(this.webRtcService.connectionSetting, connectionSetting);
            this.syncStream();
            return true;
        }
        catch (ex) {
            logger.error("ビデオチャットの接続情報の更新に失敗しました。", ex);
        }
        return false;
    }

    //#region vietual background support
    /**
     * Virtual背景
     * @param wrapper stream wrapper
     */
    async apply(wrapper: RtcMediaStreamWrapper) {
        if (getUrlParameter("ai") !== "true") return wrapper;
        const blurSize = 6;

        const { load, toMask, drawBokehEffect, toColoredPartMask } = await import("@tensorflow-models/body-pix");

        const stream = wrapper.mediaStream;
        const video = await (() => new Promise<HTMLVideoElement>(resolve => {
            const el = document.createElement("video");
            el.muted = true;
            el.srcObject = stream;
            el.width = 640;
            el.height = 480;
            el.autoplay = true;
            el.onloadedmetadata = () => {
                resolve(el);
            };
        }))();

        const bodyPix = await load();

        let bodyPixMaks = new Uint8Array();
        (async () => {
            while (true) {
                // ビデオから、人体をセグメンテーション
                const segmentation = await bodyPix.segmentPerson(video, {
                    flipHorizontal: false,
                    segmentationThreshold: 0.7,
                });
                const fgColor = { r: 0, g: 0, b: 0, a: 0 }; // 人体部分は透明
                const bgColor = { r: 132, g: 74, b: 221, a: 255 };　// 周囲はグレイ、不透明
                bodyPixMaks = segmentation.data;
                await delay(1000 / 30);
            }
        })();

        const maskData = new Uint8ClampedArray(480 * 640 * 4);
        const mask = new ImageData(maskData, 640, 480);

        const canvas = document.createElement("canvas");
        canvas.width = 640;
        canvas.height = 480;
        const ctx = canvas.getContext("2d");
        if (!ctx) return new RtcMediaStreamWrapper(new MediaStream());

        const canvas2 = document.createElement("canvas");
        canvas2.width = 640;
        canvas2.height = 480;
        const ctx2 = canvas2.getContext("2d");
        if (!ctx2) return new RtcMediaStreamWrapper(new MediaStream());

        const canvas3 = document.createElement("canvas");
        canvas3.width = 640;
        canvas3.height = 480;
        const ctx3 = canvas3.getContext("2d");
        if (!ctx3) return new RtcMediaStreamWrapper(new MediaStream());

        const canvas4 = document.createElement("canvas");
        canvas4.width = 640;
        canvas4.height = 480;
        const ctx4 = canvas4.getContext("2d");
        if (!ctx4) return new RtcMediaStreamWrapper(new MediaStream());

        const bluredVideoCanvas = document.createElement("canvas");
        bluredVideoCanvas.width = 640;
        bluredVideoCanvas.height = 480;
        const bluredVideoCanvasCtx = bluredVideoCanvas.getContext("2d");
        if (!bluredVideoCanvasCtx) return new RtcMediaStreamWrapper(new MediaStream());

        const background = new Image(640, 480);
        await new Promise(resolve => {
            background.src = "/material.png";
            background.onload = () => {
                resolve();
            };
        });

        (async () => {
            while (true) {
                await delay(1000 / 30);
                for (let h = 0; h < 480; h++) {
                    for (let w = 0; w < 640; w++) {
                        const i = (h * 640 * 4) + (w * 4);
                        if (bodyPixMaks[h * 640 + w] === 0) {
                            maskData[i] = 255;
                            maskData[i + 1] = 255;
                            maskData[i + 2] = 255;
                            maskData[i + 3] = 255;
                        }
                        else {
                            maskData[i] = 0;
                            maskData[i + 1] = 0;
                            maskData[i + 2] = 0;
                            maskData[i + 3] = 0;
                        }
                    }
                }

                // マスクを描画
                ctx2.clearRect(0, 0, 640, 480);
                ctx2.putImageData(mask, 0, 0);

                // マスクをぼかす
                ctx3.clearRect(0, 0, 640, 480);
                (ctx3 as any).filter = `blur(${blurSize}px)`;
                ctx3.drawImage(canvas2, 0, 0, 640, 480);

                ctx.clearRect(0, 0, 640, 480);
                ctx.drawImage(video, -4, -4, 648, 488);
                ctx.globalCompositeOperation = "xor";
                ctx.drawImage(canvas3, -4, -4, 648, 488);

                ctx4.clearRect(0, 0, 640, 480);

                bluredVideoCanvasCtx.drawImage(video, 0, 0, 640, 480);
                (bluredVideoCanvasCtx as any).filter = `blur(18px)`;
                ctx4.drawImage(bluredVideoCanvas, 0, 0, 640, 480);
                ctx4.drawImage(canvas, 0, 0, 640, 480);
            }
        })();
        return new RtcMediaStreamWrapper((canvas4 as any).captureStream());
    }
    // #endregion
}
